/* eslint-disable indent */
import {
	Box, Card, CardActions, CardContent, CircularProgress, Stack, Typography,
} from '@mui/material';
import { BodyText, DefaultButton, TitleMedium } from 'common/presentation/components';

import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import { ReactComponent as MessageIcon } from 'assets/icons/message.svg';
import { backoffBackground, logo } from 'assets/images';

import { zilaSlab } from 'assets/theme/theme';
import TextField from 'common/presentation/components/text-field/TextField';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import useAuthBackoffice from '../provider/auth-backoffice.provider';
import AppLinks from 'assets/applinks.routes';
import LocalStorageWrapper, { LocalStorageKeys } from 'common/utils/storage-utils';

export const LoginPageBack = () => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const { login, loading, error } = useAuthBackoffice();

	const [username, setUsername] = useState<string>('');
	const [password, setPassword] = useState<string>('');

	useEffect(() => {
		const key = LocalStorageWrapper.get<string>(LocalStorageKeys.REFRESH_TOKEN);
		const user = LocalStorageWrapper.get<string>(LocalStorageKeys.USER);
		if (key && user) {
			navigate(AppLinks.DASHBOARD);
		}
	}, []);

	const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const result = await login(username, password);
		if (result) {
			navigate(AppLinks.DASHBOARD);
		}
	};

	return (
		<Box sx={{
			backgroundImage: `url(${backoffBackground})`,
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
			height: '100vh',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		}}
		>
			<form onSubmit={handleLogin}>
			<Card sx={{
				maxWidth: 410, minHeight: 450, m: 'auto', borderRadius: 4, boxShadow: 'none',
			}}
			>
				<CardContent sx={{ textAlign: 'center', m: 2 }}>
					<Box sx={{ mb: 2 }}>
						<img src={logo} alt="Logo da volt" />
					</Box>
					<TitleMedium sx={{
						fontFamily: zilaSlab, fontWeight: 500, fontSize: 20, mb: 1,
					}}
					>
						{t('welcome_to_backoffice')}
					</TitleMedium>
					<BodyText sx={{
						fontSize: 14, mb: 4, paddingInline: 6, fontFamily: zilaSlab,
					}}
					>
						{t('welcome_info_backoffice')}
					</BodyText>

					<Stack>
						<Typography color="red">
							{error}
						</Typography>
					</Stack>

					<TextField
						sx={{
							borderRadius: 2, mb: 2, height: 50, fontFamily: zilaSlab,
						}}
						placeholder={t('label_email')}
						type="email"
						onChange={(ev) => setUsername(ev.target.value)}
						startIcon={<MessageIcon />}
					/>
					<TextField
						sx={{
							borderRadius: 2, mb: 2, height: 50, fontFamily: zilaSlab,
						}}
						placeholder={t('label_password')}
						type="password"
						onChange={(ev) => setPassword(ev.target.value)}
						startIcon={<LockIcon />}
					/>

					<Link
						to="/forgot-password"
						style={{
							color: '#7E8A16', textDecoration: 'none', display: 'flex', justifyContent: 'flex-end', fontFamily: zilaSlab,
						}}
					>
						{t('forgot_password')}
					</Link>

				</CardContent>
				<CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
					<DefaultButton
						disabled={loading}
						sx={{
							borderRadius: 2, width: 250, textTransform: 'capitalize', mb: 2, height: 50, fontSize: 16, fontFamily: zilaSlab,
						}}
						type="submit"

					>
						{loading && <CircularProgress size={30} />}
						{!loading && t('enter')}
					</DefaultButton>
				</CardActions>

			</Card>
			</form>
		</Box>

	);
};
