import React, { useEffect, useRef, useState } from 'react';
import {
	Box, Grid, Link, Skeleton, Stack, useMediaQuery, useTheme,
} from '@mui/material';
import Dimens from 'assets/dimens';
import {
	BackButton, BodyText, ProductImageView, TitleMedium,
} from 'common/presentation/components';
import { useNavigate, useParams } from 'react-router-dom';
import TitleSmall from 'common/presentation/components/title-small/TitleSmall';
import ProductCard from 'common/presentation/components/product-card/ProductCard';
import useCart from 'common/presentation/providers/cart.provider';
import { useTranslation } from 'react-i18next';
import ProductActions from '../components/ProductActions';
import useProduct from '../provider/product.provider';
import { Product } from '../../domain/entities/Product';
import AppLinks from '../../../../assets/applinks.routes';
import FileOpenIcon from '@mui/icons-material/FileOpen';

interface ItemInfoProps {
	title: string;
	info: string
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ItemInfo: React.FC<ItemInfoProps> = (props) => {
	const { title, info } = props;
	const theme = useTheme();

	return (
		<Box sx={{ ml: 3 }}>
			<BodyText sx={{
				fontSize: Dimens.FONT_SIZE_SMALL,
				fontWeight: Dimens.FONT_NORMAL,
			}}
			>
				{title}
			</BodyText>
			<BodyText sx={{
				color: theme.palette.textMain.main,
				fontWeight: Dimens.FONT_BOLD,
			}}
			>
				{info}
			</BodyText>
		</Box>
	);
};

const ProductInfo = () => {
	const theme = useTheme();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const isMd = useMediaQuery(theme.breakpoints.up('md'));
	const navigate = useNavigate();
	const { id } = useParams();

	const [width, setWidth] = useState(0);
	const elementRef = useRef<HTMLDivElement>(null);
	const [quantity, setQuantity] = useState(1);

	useEffect(() => {
		if (!elementRef.current) {
			return;
		}
		const resizeObserver = new ResizeObserver(() => {
			if (elementRef?.current?.offsetWidth !== width) {
				setWidth(elementRef?.current?.offsetWidth || 0);
			}
		});
		resizeObserver.observe(elementRef.current);

		// eslint-disable-next-line consistent-return
		return () => {
			resizeObserver.disconnect();
		};
	}, [elementRef.current]);

	const handleBackClick = () => {
		navigate(-1);
	};
	const { addToCart } = useCart();
	const { t } = useTranslation('translations');

	const { productDetail, getProductById, productDetailLoading } = useProduct();

	useEffect(() => {
		if (id) {
			getProductById(parseInt(id, 10));
			window.scrollTo(0, 0);
		}
	}, [id]);

	const handleCartClick = (product: Product) => {
		addToCart({ ...product, quantity });
		setQuantity(0);
	};
	return (
		<Box justifyContent="center" display="flex" sx={{ padding: '16px' }}>
			<Box maxWidth={Dimens.PAGE_MAX_WIDTH}>
				<BackButton onClick={handleBackClick} />
				<Grid container spacing={4}>
					<Grid item md={5} sm={12}>
						<ProductImageView
							images={productDetail.images || []}
							videoUrl={productDetail.videoUrl}
						/>
					</Grid>

					<Grid item md={4} xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
						<Stack direction="column" justifyContent="space-between" gap={1}>
							<Stack direction="row">
								<BodyText sx={{
									color: theme.palette.secondary.main,
									fontWeight: Dimens.FONT_NORMAL,
									display: 'flex',
									fontSize: 14,
									gap: 1,
								}}
								>
									<BodyText component="span" sx={{ fontSize: 14 }}>{t('code').toUpperCase()}</BodyText>
									{productDetail.reference}
								</BodyText>
							</Stack>

							<Stack direction="row">
								<BodyText sx={{
									color: theme.palette.secondary.main,
									fontWeight: Dimens.FONT_NORMAL,
									fontSize: 14,
									display: 'flex',
									gap: 1,
								}}
								>
									<BodyText component="span" sx={{ fontSize: 14 }}>{t('barcode').toUpperCase()}</BodyText>
									{productDetail.barcode}
								</BodyText>
							</Stack>
						</Stack>
						<TitleMedium sx={{ mt: 1 }}>
							{productDetail?.name}
						</TitleMedium>
						<BodyText sx={{ mt: 2 }}>
							{productDetail?.description}
						</BodyText>
						{!!productDetail.brandUrl?.trim() && (
							<Link color="#B9C92B" sx={{ fontSize: 24, mt: 2 }} href={productDetail.brandUrl} underline="none" rel="noreferrer" target="_blank">
								<Stack direction="row" gap={1}>
									<FileOpenIcon fontSize="large" />
									{t('specification')}
								</Stack>
							</Link>
						)}
					</Grid>
					<Grid item md={3} sm={12} xs={12}>
						<ProductActions
							price={`${productDetail.priceWithTaxes?.toFixed(2)}€`}
							stockStatus={productDetail.stockStatus}
							onAddToCartClick={() => handleCartClick(productDetail)}
							quantity={quantity}
							onIncrement={() => setQuantity(quantity + 1)}
							onDecrement={() => {
								if (quantity !== 0) {
									setQuantity(quantity - 1);
								}
							}}
							onFinishPurchaseClick={() => navigate(AppLinks.CART)}
							minDeliveryDays={productDetail.minDeliveryDays}
							maxDeliveryDays={productDetail.maxDeliveryDays}
						/>
					</Grid>
				</Grid>
				<div style={{
					marginTop: 100,
					marginBottom: 24,
				}}
				>

					<TitleSmall>{t('who_buy_this_product_normally_buys').toUpperCase()}</TitleSmall>
					<Box sx={{ overflow: 'auto' }}>
						<Box sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
							<Stack
								flexDirection="row"
								gap={2}
								sx={{
									overflowX: 'scroll',
									'&::-webkit-scrollbar': { display: 'none' },
									scrollbarWidth: 'none',
									msOverflowStyle: 'none',
									p: 2,

								}}
							>
								{!productDetailLoading && productDetail?.relatedProducts?.map((el) => (
									<Box key={`product-${el.productId}`} component="span">
										<ProductCard
											width="290px"
											price={el.priceWithTaxes.toString()}
											reference={el.reference || ''}
											info={el.name}
											image={el.image}
											onDetailClick={() => {
												navigate(`${AppLinks.PRODUCT_DETAIL}/${el.productId}`, { preventScrollReset: false });
											}}
											onCartClick={() => handleCartClick(el)}
										/>
									</Box>
								))}

								{productDetailLoading && [...Array(20).keys()].map((item) => (
									<Stack spacing={0.5} key={`skeleton-product-${item}`}>
										<Skeleton variant="rectangular" width={230} height={210} />
										<Skeleton variant="text" width={100} sx={{ fontSize: '1rem' }} />
										<Skeleton variant="rounded" height={40} />
										<Stack direction="row" gap={1}>
											<Skeleton variant="rectangular" sx={{ flex: 1 }} height={60} />
											<Skeleton variant="rounded" width={85} height={60} />
										</Stack>
									</Stack>
								))}
							</Stack>
						</Box>
					</Box>

				</div>
			</Box>
		</Box>
	);
};

export default ProductInfo;
