import {
	Avatar,
	Box,
	Card,
	Grid,
	Stack,
	Typography,
	useTheme,
} from '@mui/material';
import React, { FC } from 'react';
import Dimens from 'assets/dimens';
import moment from 'moment';
import { Tag } from 'primereact/tag';
import { useTranslation } from 'react-i18next';
import { getCustomerTypeColors } from 'common/utils/general.utils';
import { SizedBox } from 'common/presentation/components';

interface Props {
	fullName?: string
	lastOrderDate?: string
	hasAccount?: boolean
	phoneNumber?: string
	email?: string
	taxId?: string
}

const Usercard: FC<Props> = (props) => {
	const { t } = useTranslation('translations');
	const {
		fullName,
		lastOrderDate,
		hasAccount,
		phoneNumber,
		email,
		taxId,
	} = props;
	const theme = useTheme();

	const orderStatusTag = (account?: boolean) => {
		const hasAccountText = account ? t('regular_customer') : t('client_has_no_account');
		const { color, bgcolor } = getCustomerTypeColors(account ?? false);
		if (account) return <div />;
		return (
			<Tag
				value={hasAccountText}
				style={{ backgroundColor: bgcolor, color }}
			/>
		);
	};

	return (
		<Card
			elevation={0}
			sx={{
				bgcolor: theme.palette.cardBg.main,
				pt: Dimens.CARD_PADDING_VERTICAL,
				pb: Dimens.CARD_PADDING_VERTICAL,
				pl: Dimens.CARD_PADDING_HORIZANTAL,
				pr: Dimens.CARD_PADDING_HORIZANTAL,
			}}
		>
	  		<Stack direction="row" justifyContent="space-between" alignItems="flex-start">
				<Avatar
					sx={{
						borderRadius: Dimens.AVATAR_BORDER_RADIUS,
						height: Dimens.SECTION_SPACING_MOBILE,
						width: Dimens.SECTION_SPACING_MOBILE,
					}}
				/>
				<Box>
					<Typography sx={{ color: theme.palette.cardBg.contrastText }}>
						{fullName}
					</Typography>
					<Typography color="secondary">
						{t('last_order_on')}
						{' '}
						{moment(lastOrderDate).format('DD MMM YYYY')}
					</Typography>
				</Box>
				{orderStatusTag(hasAccount)}
    	</Stack>
			<SizedBox height={3} />
			<Grid container spacing={3}>
				<Grid item md={6} xs={12}>
					<Typography sx={{ color: theme.palette.cardBg.contrastText }}>
						{t('mobile')}
					</Typography>
					<Typography sx={{ color: theme.palette.cardBg.contrastText }}>
						{phoneNumber}
					</Typography>
				</Grid>
				<Grid item md={6} xs={12}>
					<Typography sx={{ color: theme.palette.cardBg.contrastText }}>
						{t('label_email')}
					</Typography>
					<Typography sx={{ color: theme.palette.cardBg.contrastText }}>
						{email}
					</Typography>
				</Grid>
			</Grid>
			<SizedBox height={3} />
			<Grid container spacing={3}>
				<Grid item md={6} xs={12}>
					<Typography sx={{ color: theme.palette.cardBg.contrastText }}>
						{t('phone')}
					</Typography>
					<Typography sx={{ color: theme.palette.cardBg.contrastText }}>
						{/* {customer?.} */}
					</Typography>
				</Grid>
				<Grid item md={6} xs={12}>
					<Typography sx={{ color: theme.palette.cardBg.contrastText }}>
						{t('tax_id')}
					</Typography>
					<Typography sx={{ color: theme.palette.cardBg.contrastText }}>
						{taxId}
					</Typography>
				</Grid>
			</Grid>
		</Card>
	);
};

export default Usercard;
