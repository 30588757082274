import axios from 'axios';
import { ApiResponse } from '@/common/domain/entities/api-response';
import { CatelogModel } from '../dto/catelog-model';
import { CatelogApi } from './catelog-api';

export class CatelogApiImpl implements CatelogApi {
	// eslint-disable-next-line class-methods-use-this
	async getCatelogs(): Promise<ApiResponse<CatelogModel[]>> {
		try {
			const resp = await axios.get('https://dev.host.volt.pt/store/catalogs');
			const { data } = resp;
			return data;
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}
}
