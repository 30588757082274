import ContactPage from 'features/contact/presentation';
import LandingPage from 'features/landing/presentation';
import NotFound from 'features/not-found/presentation';
import ProductCatelog from 'features/product-catelog/presentation';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Main from './Main';
import AppLinks from './assets/applinks.routes';
import Login from './features/authentication/presentation/login';
import Register from './features/authentication/presentation/register';
import { CatalogPage } from './features/backoffice/presentation/catalog/CatalogPage';
import { CustomersPage } from './features/backoffice/presentation/customers/CustomersPage';
import { InventoryPage } from './features/backoffice/presentation/inventory/InventoryPage';
import { LoginPageBack } from './features/backoffice/presentation/login/LoginPage';
import { OrdersPage } from './features/backoffice/presentation/orders/OrdersPage';
import { SettingsPage } from './features/backoffice/presentation/settin/Settings';
import Cart from './features/cart/presentation';
import ProductDetail from './features/products/presentation/product-detail';
import Products from './features/products/presentation/product-listing';
import Purchase from './features/purchase/presentation';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Dashboard } from './features/backoffice/presentation/dashboard/Dashboard';
import Backoffice from './features/backoffice/presentation';
import { ProductForm } from './features/backoffice/presentation/inventory/form/ProductForm';
import ClientArea from './features/authentication/presentation/customer-form';
import OrderDetail from './features/backoffice/presentation/orders/detail/OrderDetail';
import { OrderStoreDetail } from './features/purchase/presentation/OrderStoreDetail';
import { DeliveryTerms } from './features/terms/presentation/DeliveryTerms';
import { GuaranteesAndReturns } from './features/terms/presentation/GuaranteesAndReturns';
import { TermsAndConditionsOfUse } from './features/terms/presentation/TermsAndConditionsOfUse';
import CustomerDetail from './features/backoffice/presentation/customers/customer-detail/CustomerDetail';
// import { ProfilePage } from './features/authentication/presentation/profile/ProfilePage';

const AppRouter = () => (
	<Routes>
		{/* Add your routes here */}
		<Route path={AppLinks.LANDING} element={<Main />}>
			<Route index element={<LandingPage />} />
			<Route path={AppLinks.DELIVERY_TERMS} element={<DeliveryTerms />} />
			<Route path={AppLinks.GUARANTEES_AND_RETURNS} element={<GuaranteesAndReturns />} />
			<Route path={AppLinks.TERMS_AND_CONDITIONS_OF_USE} element={<TermsAndConditionsOfUse />} />
			<Route path={AppLinks.CONTACT_US} element={<ContactPage />} />
			<Route path={AppLinks.PRODUCT_CATELOG} element={<ProductCatelog />} />
			<Route path={AppLinks.LOGIN} element={<Login />} />
			<Route path={AppLinks.REGISTER} element={<Register />} />
			<Route path={AppLinks.CLIENT_AREA} element={<ClientArea />} />
			<Route path={`${AppLinks.PRODUCT_DETAIL}/:id`} element={<ProductDetail />} />
			<Route path={AppLinks.PRODUCTS} element={<Products />} />
			<Route path={AppLinks.CART} element={<Cart />} />
			<Route path={AppLinks.PURCHASE} element={<Purchase />} />
			<Route path={`${AppLinks.STORE_ORDER}/:id`} element={<OrderStoreDetail />} />
			<Route path="*" element={<NotFound />} />
		</Route>

		{/* Check if is logged to redirect to dashboard page or login back */}
		<Route path={AppLinks.LOGIN_BACKOFFICE} element={<LoginPageBack />} />
		<Route path={AppLinks.DASHBOARD} element={<Backoffice />}>
			<Route index element={<Dashboard />} />
			<Route path={AppLinks.ORDERS} element={<OrdersPage />} />
			<Route path={AppLinks.ORDER_DETAIL} element={<OrderDetail />} />
			<Route path={AppLinks.CUSTOMERS} element={<CustomersPage />} />
			<Route path={`${AppLinks.CUSTOMERS}/:id`} element={<CustomerDetail />} />
			<Route path={AppLinks.INVENTORY} element={<InventoryPage />} />
			<Route path={`${AppLinks.PRODUCT_FORM}/:id`} element={<ProductForm />} />
			<Route path={AppLinks.CATALOG} element={<CatalogPage />} />
			<Route path={AppLinks.SETTIGNS} element={<SettingsPage />} />
		</Route>

	</Routes>
);

export default AppRouter;
