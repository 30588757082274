import React, { useState } from 'react';
import { OrderReadModel } from 'features/purchase/data/dto/order-read-model';
import {
	Box,
	Button, CircularProgress, Collapse,
	IconButton,
	Table, TableBody, TableCell, TableHead, TableRow, useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import useAuth from '../../provider/auth.provider';
import useNotificationsContext from 'common/presentation/providers/notifications.provider';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { getOrderStatusColors } from 'common/utils/general.utils';
import { Tag } from 'primereact/tag';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const statusTag = (options: any) => {
	const { status } = options;
	const { color, bgColor } = getOrderStatusColors(status.id);
	return (
		<Tag
			value={status.name}
			style={{ backgroundColor: bgColor, color, textAlign: 'center' }}
		/>
	);
};

type OrderRowProps = {
	data: OrderReadModel
}

export const OrderRow: React.FC<OrderRowProps> = (props) => {
	const { data } = props;
	const { t } = useTranslation('translations');
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const theme = useTheme();
	const [downloading, setDownloading] = useState(false);
	const [open, setOpen] = useState(false);
	const { toast } = useNotificationsContext();

	const formatNumber = new Intl.NumberFormat('pt-EU', {
		style: 'currency',
		currency: 'EUR',
	});

	const amount = formatNumber.format(data.amount);

	const {
		getOrderProofFile,
	} = useAuth();

	const StyledTableRow = styled(TableRow)(() => ({
		padding: '34px',
		height: '60px',
		'&:nth-of-type(4n + 1)': {
			backgroundColor: theme.palette.action.hover,
		},
		// hide last border
		'&:last-child td, &:last-child th': {
			border: 0,
		},
	}));

	const downloadFile = (orderId: number) => {
		setDownloading(true);
		getOrderProofFile(orderId).then((result) => {
			if (result.success && result.file) {
				const fileUrl = window.URL.createObjectURL(result.file);
				const a = document.createElement('a');
				a.href = fileUrl;
				a.download = result.file.name;
				document.body.appendChild(a);
				a.click();
				a.remove();
			} else {
				toast.current?.show({
					severity: 'error',
					summary: 'Falha',
					detail: result.message,
				});
			}
		}).finally(() => {
			setDownloading(false);
		});
	};

	return (
		<>
			<StyledTableRow
				key={data.id}
			>
				<TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
					#
					{data.id}
				</TableCell>
				<TableCell align="right">{moment(data.createdAt).format('LL')}</TableCell>
				<TableCell align="center">{data.items.reduce((pre, cur) => pre + cur.quantity, 0)}</TableCell>
				<TableCell align="right">{amount}</TableCell>
				<TableCell align="right">{statusTag(data)}</TableCell>
				<TableCell align="right">
					<Button
						onClick={() => downloadFile(data.id)}
						sx={{ color: 'black', display: [1, 7].indexOf(data.status.id) !== -1 ? 'none' : 'inline-flex' }}
						startIcon={!downloading && <DownloadForOfflineIcon />}
						disabled={!!downloading}
					>
						{!!downloading && <CircularProgress sx={{ color: '#B9C92B', marginRight: 2 }} size={18} />}
						{t('dowload')}
					</Button>
				</TableCell>
			</StyledTableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box sx={{ margin: 1, marginLeft: 6 }}>
							<Table size="small" aria-label="purchases">
								<TableHead>
									<TableRow>
										<TableCell>{t('code')}</TableCell>
										<TableCell>{t('barcode')}</TableCell>
										<TableCell>{t('name')}</TableCell>
										<TableCell align="center">{t('quantity')}</TableCell>
										<TableCell align="center">{t('price_with_tax')}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.items.map((item) => (
										<TableRow key={item.id} sx={{ '& > *': { borderBottom: 'unset' } }}>
											<TableCell component="th" scope="row">
												{item.product.reference}
											</TableCell>
											<TableCell component="th" scope="row">
												{item.product.barcode}
											</TableCell>
											<TableCell component="th" scope="row">
												{item.product.name}
											</TableCell>
											<TableCell component="th" scope="row" align="center">
												{item.quantity}
											</TableCell>
											<TableCell component="th" scope="row" align="center">
												{formatNumber.format(item.priceWithTaxes)}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};
