import { inter } from 'assets/theme/theme';
import { Close } from '@mui/icons-material';
import { ChipProps, Chip as MuiChip, useTheme } from '@mui/material';
import React from 'react';

const Chip: React.FC<ChipProps> = (props) => {
	const { sx, ...restProps } = props;
	const theme = useTheme();
	return (
		<MuiChip
			sx={{
				'& .MuiChip-deleteIcon': {
					color: theme.palette.error.main,
				},
				fontFamily: inter,
				color: theme.palette.textMain.main,
				...sx,
			}}
			deleteIcon={<Close color="primary" />}
			{...restProps}
		/>
	);
};

export default Chip;
