import { zilaSlab } from 'assets/theme/theme';
import {
	Box,
	FormControl,
	IconButton,
	InputAdornment,
	OutlinedInput,
	Stack,
	Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { DefaultButton } from 'common/presentation/components';
import { useTranslation } from 'react-i18next';
import useProductBackoffice from '../provider/product-backoffice.provider';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { ProductModel } from '@/features/products/data/dto/product-model';
import { Dropdown } from 'primereact/dropdown';
import { StockStatus } from '@/features/products/domain/entities/Product';
import {
	getProductStatusColors,
	getStockColors,
} from 'common/utils/general.utils';
import { ToggleButton } from 'primereact/togglebutton';
import { FilterParams } from '../../data/remote/product-api';
import { Toast } from 'primereact/toast';
import SearchIcon from '@mui/icons-material/Search';
import { ProgressBar } from 'primereact/progressbar';
import { useDebounce } from 'primereact/hooks';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import { ModalUploadExcel } from './modals/ModalUploadExcel';

export const InventoryPage = () => {
	const { t } = useTranslation();
	const {
		getProductByFilter,
		products,
		productsFetchState,
		setProducts,
		productToggleStatus,
		toggleState,
		updateProductStockStatus,
		updateStockState,
	} = useProductBackoffice();
	const navigate = useNavigate();
	const [selectedProducts, setSelectedProducts] = useState<any>(null);
	const [totalOfProducts, setTotalOfProducts] = useState<number>(0);
	const toast = useRef<Toast>(null);
	const [inputValue, debouncedValue, setInputValue] = useDebounce('', 400);
	const [openUpload, setOpenUpload] = useState(false);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [filter, setFilter] = useState<FilterParams>({
		size: 20,
	} as FilterParams);

	const [statuses] = useState<StockStatus[]>([
		{ stockStatusId: 1, name: 'Sem Stock', description: '' },
		{ stockStatusId: 2, name: 'Stock Baixo', description: '' },
		{ stockStatusId: 3, name: 'Stock Médio', description: '' },
		{ stockStatusId: 4, name: 'Stock Alto', description: '' },
	]);
	const [lazyState, setlazyState] = useState({
		first: 0,
		rows: 30,
		page: 1,
		sortField: null,
		sortOrder: null,
		filters: {},
	});

	const [productStatuses] = useState<string[]>([t('active'), t('inactive')]);

	useEffect(() => {
		setFilter(
			(prev) => ({
				...prev,
				size: lazyState.rows,
				page: lazyState.page,
				search: debouncedValue,
			}),
		);
	}, [lazyState, debouncedValue]);

	useEffect(() => {
		getProductByFilter(filter).then((total) => {
			if (total) {
				setTotalOfProducts(total);
			}
		});
	}, [filter]);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const stockTag = (options: any) => {
		const { stockStatus } = options;
		const { color, bgColor } = getStockColors(stockStatus.stockStatusId);
		return (
			<Tag
				value={stockStatus.name}
				style={{ backgroundColor: bgColor, color }}
			/>
		);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const productStatusTag = (options: any) => {
		const { isActive } = options;
		const { color, bgColor } = getProductStatusColors(isActive);
		return (
			<Tag
				value={isActive ? productStatuses[0] : productStatuses[1]}
				style={{
					backgroundColor: bgColor,
					color,
					fontSize: 14,
					paddingLeft: 8,
					paddingRight: 8,
				}}
			/>
		);
	};

	const priceBodyTemplate = (rowData: ProductModel) => new Intl.NumberFormat('pt-EU', {
		style: 'currency',
		currency: 'EUR',
	}).format(rowData.priceWithTaxes);

	const statusEditor = (options: any) => (
		<Dropdown
			value={options?.value}
			options={statuses}
			onChange={(e) => options.editorCallback(e.value)}
			placeholder="Select a Status"
			optionLabel="name"
			// eslint-disable-next-line react/no-unstable-nested-components
			itemTemplate={(ev) => {
				const { color, bgColor } = getStockColors(ev.stockStatusId);
				return (
					<Stack>
						<Tag value={ev.name} style={{ backgroundColor: bgColor, color }} />
					</Stack>
				);
			}}
		/>
	);

	const productStatusEditor = (options: any) => (
		<ToggleButton
			checked={options.value}
			onChange={(e) => options.editorCallback(e.value)}
			onLabel={t('active')}
			offLabel={t('inactive')}
			color="red"
			style={{ height: 35 }}
		/>
	);

	const onRowEditComplete = (e: any) => {
		const list = [...products];
		const { newData, index } = e;
		const old = list[index];
		const prod = newData as ProductModel;
		list[index] = prod;
		setProducts(list);

		Promise.all([
			updateProductStockStatus(prod.productId, prod.stockStatus.stockStatusId),
			productToggleStatus(prod.productId),
		])
			.then(() => {
				toast.current?.show({
					severity: 'success',
					summary: 'Sucesso',
					detail: 'Item atualizado com sucesso',
				});
			})
			.catch(() => {
				list[index] = old;
				setProducts(list);
				toast.current?.show({
					severity: 'error',
					summary: 'Falha',
					detail: 'Uma ou mais informação não foi possível ser salva.',
				});
			});
	};

	const onPage = (event: any) => {
		setlazyState(event);
	};

	return (
		<Box>
			<Toast ref={toast} />
			<ModalUploadExcel
				open={openUpload}
				onOpenChange={(value) => setOpenUpload(value)}
				onClose={() => console.log()}
			/>
			<Stack direction="row">
				<Typography fontSize={22} fontFamily={zilaSlab} fontWeight={400}>
					Produtos e Inventário
				</Typography>
				<Box sx={{ flexGrow: 1 }} />
				<FormControl
					variant="outlined"
					sx={{ width: '40ch', alignSelf: 'start', mr: 4 }}
				>
					<OutlinedInput
						size="small"
						value={inputValue}
						onChange={(e) => setInputValue(e.target.value)}
						placeholder={t('Produtos...')}
						startAdornment={(
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						)}
						inputProps={{
							'aria-label': 'search',
						}}
					/>
				</FormControl>
				<DefaultButton sx={{ borderRadius: 3, fontSize: 10 }} onClick={() => setOpenUpload(true)}>
					<i className="pi pi-upload" style={{ marginRight: 8 }} />
					{t('import')}
				</DefaultButton>
			</Stack>

			<Box className="card" mt={6}>
				{(toggleState.loading || updateStockState.loading || productsFetchState.loading) && (
					<ProgressBar mode="indeterminate" style={{ height: '6px' }} />
				)}
				<DataTable
					value={products}
					lazy
					paginator
					first={lazyState.first}
					onPage={onPage}
					rows={lazyState.rows}
					totalRecords={totalOfProducts}
					selection={selectedProducts}
					onRowEditComplete={onRowEditComplete}
					editMode="row"
					loading={toggleState.loading || updateStockState.loading || productsFetchState.loading}
					onSelectionChange={(e) => setSelectedProducts(e.value)}
					dataKey="productId"
					tableStyle={{ minWidth: '50rem' }}
				>
					<Column
						field="productId"
						header="ID"
						align="center"
						// eslint-disable-next-line react/no-unstable-nested-components
						body={(rowData: ProductModel) => (
							<Typography sx={{ color: '#B9C92B', fontWeight: 600 }}>
								{`#${rowData.productId}`}
							</Typography>
						)}
					/>
					<Column field="name" bodyStyle={{ fontSize: 14 }} header="Nome" />
					<Column
						field="priceWithTaxes"
						align="center"
						header="Preço uni. particular"
						style={{ fontWeight: 600 }}
						body={(options) => priceBodyTemplate(options)}
					/>

					<Column
						field="priceWithTaxesForCompanies"
						align="center"
						style={{ fontWeight: 600 }}
						header="Preço uni. empresarial"
						body={(options) => priceBodyTemplate(options)}
					/>

					<Column
						field="stockStatus"
						header="Stock de produto"
						align="center"
						body={(options) => stockTag(options)}
						editor={(options) => statusEditor(options)}
					/>

					<Column
						field="isActive"
						header="Status"
						align="center"
						body={(options) => productStatusTag(options)}
						editor={(options) => productStatusEditor(options)}
						headerStyle={{ width: '10%', minWidth: '8rem' }}
					/>
					<Column
						rowEditor
						headerStyle={{ width: '10%', minWidth: '8rem' }}
						bodyStyle={{ textAlign: 'center' }}
					/>
					<Column
						headerStyle={{ width: '10%', minWidth: '8rem' }}
						// eslint-disable-next-line react/no-unstable-nested-components
						body={(options) => (
							<IconButton onClick={() => navigate(`${AppLinks.PRODUCT_FORM}/${options.productId}`)}><ArrowForwardIosIcon /></IconButton>
						)}
						bodyStyle={{ textAlign: 'center' }}
					/>
				</DataTable>
			</Box>
		</Box>
	);
};
