import React, {
	createContext,
	useContext,
	useMemo,
	useState,
} from 'react';
import SettingApiImpl from '../../data/remote/setting-api-impl';
import { ApiResponse } from 'common/domain/entities/api-response';
import { FullUserBackofficeModel, UserUpdateReqErrors } from '../../data/dto/user-backoffice-model';
import { CommonApiImpl } from 'common/data/remote/common-api-impl';
import { IdNameModel } from 'common/data/dto/id-name-model';
import { UserUpdateModel } from 'features/authentication/data/dto/user-model';
import { ShipmentBackoffice } from '../../data/dto/shipment-backoffice-model';
import { FilterParams } from '../../data/remote/catelog-api';

interface FetchState {
	loading: boolean;
	error: string;
}

type TContextProps = {
	fullUserProfile: FullUserBackofficeModel | null
	userFetchState: FetchState
	setFullUserProfile: (user: FullUserBackofficeModel) => void
	getUserProfile: () => Promise<boolean>
	getCountries: () => Promise<boolean>
	countries: IdNameModel[]
	getDistricts: (id: number) => Promise<boolean>
	districts: IdNameModel[]

	updateUserProfile: () => Promise<boolean>
	userProfileErrors: UserUpdateReqErrors
	setUserProfileErrors: (params: UserUpdateReqErrors) => void

	shipments: ShipmentBackoffice[]
	setShipments: (params: ShipmentBackoffice[]) => void
	shipmentLoading: boolean
	getShipments: (params: FilterParams) => Promise<{
		success: boolean;
		message: string;
		total: number;
	}>

	updateShipmentState: FetchState
	updateShipment: (params: ShipmentBackoffice) => Promise<boolean>
}

const SettingsContext = createContext({} as TContextProps);

export const SettingsBackofficeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const categoriesApi = new SettingApiImpl();
	const commonApi = new CommonApiImpl();

	const [fullUserProfile, setFullUserProfile] = useState<FullUserBackofficeModel | null>(null);
	const [userFetchState, setUserFetchState] = useState<FetchState>({
		loading: false,
		error: '',
	});
	const [userProfileErrors, setUserProfileErrors] = useState<UserUpdateReqErrors>({
		fullName: '',
		taxId: '',
		phoneNumber: '',
		secondaryPhoneNumber: '',
		companyName: '',
		districtId: '',
		streetAddress: '',
		zipCode: '',
		cityName: '',
		profileImageFileName: '',
	});
	const [userUpdateFetchState, setUserUpdateFetchState] = useState({
		loading: false,
		error: '',
	 });

	const [countries, setCountries] = useState([] as IdNameModel[]);
	const [districts, setDistricts] = useState([] as IdNameModel[]);
	const [shipments, setShipments] = useState<ShipmentBackoffice[]>([]);
	const [shipmentLoading, setShipmentLoading] = useState(false);
	const [updateShipmentState, setUpdateShipmentState] = useState<FetchState>({
		loading: false,
		error: '',
	});

	const getUserProfile = async () => {
		setUserFetchState({ ...userFetchState, loading: true });

		return categoriesApi.getUserProfile().then((res) => {
			setFullUserProfile(res.data);
			return true;
		}).catch((err) => {
			const responseError = err as ApiResponse<void>;
			setUserFetchState((prev: FetchState) => ({ ...prev, error: responseError.message }));
			return false;
		}).finally(() => {
			setUserFetchState({ ...userFetchState, loading: false });
		});
	};
	const getDistricts = (countryId: number) => {
		setUserFetchState({ ...userFetchState, loading: true });

		return commonApi.getDistricts(countryId).then((res) => {
			setDistricts(res.data);
			return true;
		}).catch((err) => {
			const responseError = err as ApiResponse<void>;
			setUserFetchState((prev: FetchState) => ({ ...prev, error: responseError.message }));
			return false;
		}).finally(() => {
			setUserFetchState({ ...userFetchState, loading: false });
		});
	};
	const getCountries = () => {
		setUserFetchState({ ...userFetchState, loading: true });

		return commonApi.getCountries().then((res) => {
			setCountries(res.data);
			getDistricts(res.data[0].id);
			return true;
		}).catch((err) => {
			const responseError = err as ApiResponse<void>;
			setUserFetchState((prev: FetchState) => ({ ...prev, error: responseError.message }));
			return false;
		}).finally(() => {
			setUserFetchState({ ...userFetchState, loading: false });
		});
	};
	const updateUserProfile = () => {
		setUserUpdateFetchState({ ...userUpdateFetchState, loading: true });

		const userProfile = {
			fullName: fullUserProfile?.fullName,
			taxId: fullUserProfile?.taxId,
			phoneNumber: fullUserProfile?.phoneNumber,
			secondaryPhoneNumber: fullUserProfile?.secondaryPhoneNumber,
			companyName: fullUserProfile?.companyName,
			districtId: fullUserProfile?.district?.id,
			streetAddress: fullUserProfile?.streetAddress,
			zipCode: fullUserProfile?.zipCode,
			cityName: fullUserProfile?.cityName,
			profileImageFileName: fullUserProfile?.profileImage || '',
		} as UserUpdateModel;

		return categoriesApi.updateUserProfile(userProfile).then(() => true).catch((err) => {
			const responseError = err as ApiResponse<void>;
			setUserUpdateFetchState((prev: FetchState) => ({ ...prev, error: responseError.message }));
			return false;
		}).finally(() => {
			setUserUpdateFetchState({ ...userUpdateFetchState, loading: false });
		});
	};

	const getShipments = (params: FilterParams) => {
		setShipmentLoading(true);
		return categoriesApi.getShipment(params).then((res) => {
			setShipments(res.data);
			return ({ success: true, message: '', total: res.total });
		}).catch((err) => {
			const responseError = err as ApiResponse<void>;
			return { success: false, message: responseError.message, total: 0 };
		}).finally(() => setShipmentLoading(false));
	};

	const updateShipment = async (params: ShipmentBackoffice) => {
		setUpdateShipmentState({ loading: true, error: '' });
		return categoriesApi.updateShipment(params).then(() => true).catch((err) => {
			const responseError = err as ApiResponse<void>;
			setUpdateShipmentState((prev: FetchState) => ({
				...prev, error: responseError.message,
			}));
			return false;
		}).finally(() => {
			setUpdateShipmentState((prev: FetchState) => ({ ...prev, loading: false }));
		});
	};

	const value = useMemo(() => ({
		userFetchState,
		fullUserProfile,
		setFullUserProfile,
		getUserProfile,
		getCountries,
		getDistricts,
		countries,
		districts,
		updateUserProfile,
		userProfileErrors,
		setUserProfileErrors,
		shipments,
		shipmentLoading,
		getShipments,
		updateShipmentState,
		updateShipment,
		setShipments,
	}), [
		userFetchState,
		fullUserProfile,
		countries,
		districts,
		shipments,
		shipmentLoading,
		updateShipmentState,
	]);

	return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
};

export default function useSettingsBackoffice() {
	return useContext(SettingsContext);
}
