import Dimens from 'assets/dimens';
import {
	BodyText, DefaultButton, DefaultDivider, DefaultDropdown, ListItemWithIcon,
} from 'common/presentation/components';
import {
	Box, CircularProgress, Collapse, FormControlLabel, Radio, RadioGroup, Stack, Typography, useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { zilaSlab } from 'assets/theme/theme';
import TitleSmall from 'common/presentation/components/title-small/TitleSmall';
import { AssignmentReturn, CarbonDeliveryParcel, CarbonShoppingCart } from 'assets/icons';
import { useTranslation } from 'react-i18next';
import { CheckoutForm } from 'common/presentation/components/checkout-form/checkout-form';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import useAuth from 'features/authentication/presentation/provider/auth.provider';
import useCart from 'common/presentation/providers/cart.provider';

export type PaymentMethod = 'CARD' | 'MULTIBANCO';
interface CartSideItemProps {
	price: number;
	shippingPrice?: number;
	discount?: number;
	buttonLabel?: string,
	handleFinalizeClick?: (
		stripe?: Stripe, elements?: StripeElements, paymentMethod?: PaymentMethod) => void
	hideCheckout?: boolean
	loading?: boolean;
	paymentForm?: boolean;
	minDeliveryDays?: number,
	maxDeliveryDays?: number,
	deliveryEstimate?: boolean
}

const CartSideItem: React.FC<CartSideItemProps> = (props) => {
	const {
		price, handleFinalizeClick, hideCheckout, buttonLabel = 'checkout',
		loading = false, paymentForm = false,
		minDeliveryDays, maxDeliveryDays, shippingPrice = 0, discount,
		deliveryEstimate,
	} = props;
	const [stripe, setStripe] = useState<Stripe>();
	const [elements, setElements] = useState<StripeElements>();
	const theme = useTheme();
	const { t } = useTranslation('translations');
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>('CARD');
	const navigate = useNavigate();
	const {
		getDistricts, districts,
	} = useAuth();

	const {
		getDeliveryEstimate,
		deliveryEstimate: delivery,
		cartProducts,
	} = useCart();
	const [districtId, setDistrictId] = useState(11);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [deliveryEstimatePrice, setDeliveryEstimatePrice] = useState(0);

	useEffect(() => {
		if (deliveryEstimate) {
			getDistricts(1);
			getDeliveryEstimate(11);
		}
	}, []);

	useEffect(() => {
		setDeliveryEstimatePrice(delivery?.price ?? 0);
	}, [delivery]);

	useEffect(() => {
		getDeliveryEstimate(districtId);
	}, [cartProducts, districtId]);

	const handleDistrictChange = (value: number) => {
		setDistrictId(value);
	};

	return (
		<Box sx={{ border: `1px solid ${theme.palette.primary.main}`, p: 2 }}>
			{paymentForm && (
				<Stack mb={6}>
					<RadioGroup
						value={paymentMethod}
						onChange={(e, value) => setPaymentMethod(value as PaymentMethod)}
					>
						<FormControlLabel value="CARD" control={<Radio />} label={t('card_or_paypal')} />
						<Stack sx={{
							pl: 4, pt: 1, pb: 1, position: 'relative',
						}}
						>
							<Collapse in={paymentMethod === 'CARD'}>
								<CheckoutForm
									didStripeLoad={(st) => setStripe(st)}
									didElementLoad={(el) => setElements(el)}
								/>
							</Collapse>
						</Stack>
						<FormControlLabel value="MULTIBANCO" control={<Radio />} label="Multibanco" />

						<Collapse in={paymentMethod === 'MULTIBANCO'}>
							<Stack sx={{
								pl: 4, pt: 1, pb: 1, position: 'relative',
							}}
							>
								<Typography fontSize={14} sx={{ border: '1px solid #ccc', p: 1, textAlign: 'center' }}>
									{t('multibanco_tip')}
								</Typography>
							</Stack>
						</Collapse>
					</RadioGroup>

				</Stack>
			)}

			{!!deliveryEstimate && (
				<Stack mb={2}>
					<BodyText sx={{ fontSize: Dimens.FONT_SIZE_SMALL, fontFamily: zilaSlab, textAlign: 'center' }}>
						{t('estimate_delivery_tip')}
					</BodyText>
					<DefaultDropdown
						label={t('customer_page_label_city')}
						value={districtId.toString()}
						onChange={(e) => handleDistrictChange(parseInt(e.target.value, 10))}
						options={districts}
						renderItem={(item) => ({ optionLabel: item.name, optionValue: item.id })}
					/>
				</Stack>
			)}

			<Stack direction="row" justifyContent="space-between">
				<BodyText sx={{ fontSize: Dimens.FONT_SIZE_SMALL, fontFamily: zilaSlab }}>
					{t('cart_subtotal')}
				</BodyText>
				<BodyText sx={{ fontSize: Dimens.FONT_SIZE_SMALL_TITLE, fontWeight: Dimens.FONT_BOLD }}>
					{`${Number.parseFloat(`${price}`).toFixed(2) || 0} €`}
				</BodyText>
			</Stack>

			{!!deliveryEstimate && (
				<Stack direction="row" justifyContent="space-between" sx={{ mt: 1 }}>
					<BodyText sx={{ fontSize: Dimens.FONT_SIZE_SMALL, fontFamily: zilaSlab }}>
						{t('shipping_cost')}
					</BodyText>
					<BodyText sx={{
						fontSize: Dimens.FONT_SIZE_SMALL_TITLE,
						fontWeight: Dimens.FONT_BOLD,
					}}
					>
						{`${Number.parseFloat(`${deliveryEstimatePrice}`).toFixed(2) || 0} €`}
					</BodyText>
				</Stack>
			)}

			{!deliveryEstimate && !!shippingPrice && shippingPrice !== 0 && (
				<Stack direction="row" justifyContent="space-between" sx={{ mt: 1 }}>
					<BodyText sx={{ fontSize: Dimens.FONT_SIZE_SMALL, fontFamily: zilaSlab }}>
						{t('shipping_cost')}
					</BodyText>
					<BodyText sx={{
						fontSize: Dimens.FONT_SIZE_SMALL_TITLE,
						fontWeight: Dimens.FONT_BOLD,
					}}
					>
						{`${Number.parseFloat(`${shippingPrice}`).toFixed(2) || 0} €`}
					</BodyText>
				</Stack>
			)}

			{!!discount && discount !== 0 && (
				<Stack direction="row" justifyContent="space-between" sx={{ mt: 1 }}>
					<BodyText sx={{ fontSize: Dimens.FONT_SIZE_SMALL, fontFamily: zilaSlab }}>
						{t('discount')}
					</BodyText>
					<BodyText sx={{
						fontSize: Dimens.FONT_SIZE_SMALL_TITLE,
						fontWeight: Dimens.FONT_BOLD,
						color: theme.palette.error.main,
					}}
					>
						-
						{`${Number.parseFloat(`${discount}`).toFixed(2) || 0} €`}
					</BodyText>
				</Stack>
			)}

			<DefaultDivider sx={{ mt: 2, mb: 2 }} width="100%" />
			<Stack direction="row" justifyContent="end" sx={{ mt: 1 }} alignItems="flex-end">
				{/* <BodyText sx={{ fontSize: Dimens.FONT_SIZE_SMALL }}>
					{t('business_customer_discount')}
				</BodyText> */}
				<TitleSmall sx={{
					fontSize: Dimens.FONT_SIZE_LARGE_SMALL,
					fontWeight: Dimens.FONT_BOLD,
				}}
				>
					{`${Number.parseFloat(`${price + (deliveryEstimate ? deliveryEstimatePrice : shippingPrice) - (discount ?? 0)}`).toFixed(2) || 0} €`}
				</TitleSmall>
			</Stack>
			<Box sx={{ mt: 4 }}>
				{minDeliveryDays && minDeliveryDays && (<ListItemWithIcon title={`Entregas entre ${minDeliveryDays} a ${maxDeliveryDays} dias úteis`} icon={<CarbonDeliveryParcel />} />)}
				<ListItemWithIcon sx={{ cursor: 'pointer' }} onClick={() => navigate(AppLinks.GUARANTEES_AND_RETURNS)} title={t('return_policy')} icon={<AssignmentReturn />} />
			</Box>
			{
				!hideCheckout && (
					<Stack alignItems="center" sx={{ mt: 3, mb: 1 }}>
						<DefaultButton
							disabled={cartProducts.length === 0 || loading}
							onClick={() => handleFinalizeClick?.call(0, stripe, elements, paymentMethod)}
							endIcon={<CarbonShoppingCart />}
						>
							{loading && <CircularProgress color="secondary" size={18} sx={{ mr: 1 }} />}
							{t(buttonLabel)}
						</DefaultButton>
					</Stack>
				)
			}
		</Box>
	);
};

export default CartSideItem;
