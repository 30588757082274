import { DefaultButton, DefaultDropdown, SizedBox } from 'common/presentation/components';
import {
	Box,
	Grid,
	SelectChangeEvent,
	Stack,
	Typography,
	useTheme,
} from '@mui/material';
import React, { useEffect } from 'react';
import Dimens from 'assets/dimens';
import { inter } from 'assets/theme/theme';
import { Chip, TextInput } from '../../components';
import { DomainVerification } from '@mui/icons-material';
import { ProgressBar } from 'primereact/progressbar';
import useCategoriesBackoffice from '../../provider/categories-backoffice.provider';

const Categories = () => {
	const theme = useTheme();

	const {
		getProductCategories,
		categories,
		categoriesFetchState,
		subCategories,
		subCategoryFetchState,
		subCategoriesC,
		subCategoryFetchStateC,
		selectedCategory,
		setSelectedCategory,
		setSelectedSubCategory,
		getProductSubCategories,
		getProductSubCategoriesC,
		selectedSubCategory,
	} = useCategoriesBackoffice();

	useEffect(() => {
		getProductCategories();
	}, []);

	const handleDelete = (ind: number) => {
		// eslint-disable-next-line no-console
		console.log(ind);
	};

	const handleCatBChange = (e: SelectChangeEvent) => {
		setSelectedSubCategory(parseInt(e.target.value, 10));
		getProductSubCategoriesC(parseInt(e.target.value, 10));
	};

	const handleCategoryChange = (e: SelectChangeEvent) => {
		setSelectedCategory(parseInt(e.target.value, 10));
		getProductSubCategories(parseInt(e.target.value, 10));
	};

	return (
		<Box sx={{
			bgcolor: theme.palette.background.default,
			pl: 2,
			pb: 2,
			pr: 2,
		}}
		>
			{(subCategoryFetchStateC.loading || categoriesFetchState.loading || subCategoryFetchState.loading) && <ProgressBar mode="indeterminate" style={{ height: '6px' }} />}
			<SizedBox height={2} />
			<Typography
				color={theme.palette.textLight.main}
				fontSize={Dimens.FONT_SIZE_SMALL}
				fontFamily={inter}
				fontWeight={400}
			>
					Categoria A
			</Typography>
			<SizedBox height={2} />
			<Stack direction="row" flexWrap="wrap" useFlexGap spacing={2}>
				{(!categoriesFetchState.error || !categoriesFetchState.loading)
					&& categories.map((el, index) => (
						<Chip
							label={el.name}
							key={el.productCategoryId}
							onDelete={() => handleDelete(index)}
						/>
					))}
			</Stack>
			<SizedBox height={3} />
			<Stack direction="row" spacing={2}>
				<TextInput placeholder="Adicione uma nova" />
				<DefaultButton
					startIcon={<DomainVerification />}
					sx={{
						borderRadius: Dimens.CARD_BORDER_RADIUS,
						textTransform: 'none',
						fontFamily: inter,
					}}
				>
					Adicionar
				</DefaultButton>
			</Stack>
			<SizedBox height={2} />
			<Stack direction="row" justifyContent="space-between">
				<Typography
					color={theme.palette.textLight.main}
					fontSize={Dimens.FONT_SIZE_SMALL}
					fontFamily={inter}
					fontWeight={400}
				>
					Categoria B
				</Typography>
				<Typography
					color={theme.palette.secondary.main}
					fontSize={Dimens.FONT_SIZE_SMALL}
					fontFamily={inter}
					fontWeight={400}
				>
					Optional
				</Typography>
			</Stack>
			<SizedBox height={2} />
			<Grid container>
				<Grid item md={4} xs={12}>
					<DefaultDropdown
						label="Dentro de categoria A"
						value={categories.length !== 0 ? selectedCategory : ''}
						borderRadius="5px"
						size="medium"
						onChange={handleCategoryChange}
						options={categories}
						renderItem={(item) => ({ optionLabel: item.name, optionValue: item.productCategoryId })}
					/>
				</Grid>
			</Grid>
			<SizedBox height={2} />
			<Stack direction="row" flexWrap="wrap" useFlexGap spacing={2}>
				{(!subCategoryFetchState.error || !subCategoryFetchState.loading)
				&& subCategories.map((el, index) => (
					<Chip
						label={el.name}
						key={el.productCategoryId}
						onDelete={() => handleDelete(index)}
					/>
				))}
			</Stack>
			<SizedBox height={3} />
			<Stack direction="row" spacing={2}>
				<TextInput placeholder="Adicione uma nova" />
				<DefaultButton
					startIcon={<DomainVerification />}
					sx={{
						borderRadius: Dimens.CARD_BORDER_RADIUS,
						textTransform: 'none',
						fontFamily: inter,
					}}
				>
					Adicionar
				</DefaultButton>
			</Stack>
			<SizedBox height={2} />
			<Stack direction="row" justifyContent="space-between">
				<Typography
					color={theme.palette.textLight.main}
					fontSize={Dimens.FONT_SIZE_SMALL}
					fontFamily={inter}
					fontWeight={400}
				>
					Categoria C
				</Typography>
				<Typography
					color={theme.palette.secondary.main}
					fontSize={Dimens.FONT_SIZE_SMALL}
					fontFamily={inter}
					fontWeight={400}
				>
					Optional
				</Typography>
			</Stack>
			<SizedBox height={2} />
			<Grid container>
				<Grid item md={4} xs={12}>
					<DefaultDropdown
						label="Dentro de sub-categoria B"
						value={subCategories.length > 0 ? selectedSubCategory : ''}
						borderRadius="5px"
						onChange={handleCatBChange}
						size="medium"
						options={subCategories}
						renderItem={(item) => ({ optionLabel: item.name, optionValue: item.productCategoryId })}
					/>
				</Grid>
			</Grid>
			<SizedBox height={2} />
			<Stack direction="row" flexWrap="wrap" useFlexGap spacing={2}>
				{(!subCategoryFetchStateC.error || !subCategoryFetchStateC.loading)
					&& subCategoriesC.map((el, index) => (
						<Chip
							label={el.name}
							key={el.productCategoryId}
							onDelete={() => handleDelete(index)}
						/>
					))}
			</Stack>
			<SizedBox height={3} />
			<Stack direction="row" spacing={2}>
				<TextInput placeholder="Adicione uma nova" />
				<DefaultButton
					startIcon={<DomainVerification />}
					sx={{
						borderRadius: Dimens.CARD_BORDER_RADIUS,
						textTransform: 'none',
						fontFamily: inter,
					}}
				>
					Adicionar
				</DefaultButton>
			</Stack>
		</Box>
	);
};

export default Categories;
