/* eslint-disable class-methods-use-this */
import { ApiResponse } from 'common/domain/entities/api-response';
import { ProductCategoryModel } from 'features/products/data/dto/product-category-model';
import { ProductModel } from 'features/products/data/dto/product-model';
import { ProductCreateModel } from '../dto/product-create-model';
import { FilterParams, ProductApi } from './product-api';
import http, { uploadFile } from './http';
import axios, { AxiosError, AxiosProgressEvent } from 'axios';
import { serializeFormQuery } from 'common/utils/general.utils';
import { ProductUpdateModel } from '../dto/product-update-model';

export default class ProductApiImpl implements ProductApi {
	apiService = http;

	async uploadImage(
		file: File,
		onUploadProgress: ((progressEvent: AxiosProgressEvent) => void) | undefined,
	): Promise<ApiResponse<string>> {
		return uploadFile(file, '/file/images', onUploadProgress);
	}

	async uploadExcel(
		file: File,
		onUploadProgress: ((progressEvent: AxiosProgressEvent) => void) | undefined,
	): Promise<ApiResponse<string>> {
		return uploadFile(file, '/files/excel-upload', onUploadProgress);
	}

	async uploadXml(
		file: File,
		onUploadProgress: ((progressEvent: AxiosProgressEvent) => void) | undefined,
	): Promise<ApiResponse<string>> {
		return uploadFile(file, '/files/xml-upload', onUploadProgress);
	}

	async getProductsByFilter(filter: FilterParams): Promise<ApiResponse<ProductModel[]>> {
		try {
			const query = serializeFormQuery(filter);
			const resp = await this.apiService.get(`/products${query}`);
			const data = resp.data as ApiResponse<ProductModel[]>;
			return Promise.resolve(data);
		} catch (err) {
			if (axios.isAxiosError(err)) {
				const errData = err as AxiosError;
				if (errData.response?.data) {
					const data = errData.response?.data as ApiResponse<void>;
					return Promise.reject(data);
				}
			}
			const error = { message: 'unexpected_error' } as ApiResponse<void>;
			return Promise.reject(error);
		}
	}

	async createProduct(product: ProductCreateModel): Promise<ApiResponse<ProductCategoryModel>> {
		try {
			const resp = await this.apiService.post('/products', {
				...product,
			});
			const data = resp.data as ApiResponse<ProductCategoryModel>;
			return Promise.resolve(data);
		} catch (err) {
			if (axios.isAxiosError(err)) {
				const errData = err as AxiosError;
				if (errData.response?.data) {
					const data = errData.response?.data as ApiResponse<void>;
					return Promise.reject(data);
				}
			}
			const error = { message: 'unexpected_error' } as ApiResponse<void>;
			return Promise.reject(error);
		}
	}

	async updateProduct(id: number, product: ProductUpdateModel) {
		try {
			const resp = await this.apiService.patch(`/products/${id}`, {
				...product,
			});
			const data = resp.data as ApiResponse<ProductCategoryModel>;
			return Promise.resolve(data);
		} catch (err) {
			if (axios.isAxiosError(err)) {
				const errData = err as AxiosError;
				if (errData.response?.data) {
					const data = errData.response?.data as ApiResponse<void>;
					return Promise.reject(data);
				}
			}
			const error = { message: 'unexpected_error' } as ApiResponse<void>;
			return Promise.reject(error);
		}
	}

	async getProductsById(id: number): Promise<ApiResponse<ProductModel>> {
		try {
			const resp = await this.apiService.get(`/products/${id}`);
			const data = resp.data as ApiResponse<ProductModel>;
			return Promise.resolve(data);
		} catch (err) {
			if (axios.isAxiosError(err)) {
				const errData = err as AxiosError;
				if (errData.response?.data) {
					const data = errData.response?.data as ApiResponse<void>;
					return Promise.reject(data);
				}
			}
			const error = { message: 'unexpected_error' } as ApiResponse<void>;
			return Promise.reject(error);
		}
	}

	async productToggleStatus(id: number): Promise<ApiResponse<void>> {
		try {
			const resp = await this.apiService.patch(`/products/toggle/${id}`);
			const data = resp.data as ApiResponse<void>;
			return data;
		} catch (err) {
			if (axios.isAxiosError(err)) {
				const errData = err as AxiosError;
				if (errData.response?.data) {
					const data = errData.response?.data as ApiResponse<void>;
					return Promise.reject(data);
				}
			}
			const error = { message: 'unexpected_error' } as ApiResponse<void>;
			return Promise.reject(error);
		}
	}

	async updateProductStockStatus(id: number, status: number): Promise<ApiResponse<void>> {
		try {
			const resp = await this.apiService.patch(`/products/change-stock-status/${id}`, {
				stockStatusId: status,
			});
			const data = resp.data as ApiResponse<void>;
			return data;
		} catch (err) {
			if (axios.isAxiosError(err)) {
				const errData = err as AxiosError;
				if (errData.response?.data) {
					const data = errData.response?.data as ApiResponse<void>;
					return Promise.reject(data);
				}
			}
			const error = { message: 'unexpected_error' } as ApiResponse<void>;
			return Promise.reject(error);
		}
	}
}
