/* eslint-disable indent */
import Box from '@mui/material/Box';
import React from 'react';

import { CardInfo } from './card/CardInfo';

export const Dashboard = () => (
	<Box sx={{ display: 'flex' }}>
		<CardInfo />
	</Box>
	);
