import React, {
	createContext, useContext, useMemo, useState,
} from 'react';
import { ApiResponse } from 'common/domain/entities/api-response';
import { ProductModel } from 'features/products/data/dto/product-model';
import ProductApiImpl from '../../data/remote/product-api-impl';
import { FilterParams } from '../../data/remote/product-api';
import { ProductUpdateModel } from '../../data/dto/product-update-model';

interface FetchState {
	loading: boolean;
	error: string;
}

type TContextProps = {
	products: ProductModel[];
	setProducts: React.Dispatch<React.SetStateAction<ProductModel[]>>;
	productsFetchState: FetchState;
	getProductByFilter: (filter: FilterParams) => Promise<number | null>;

	updateProduct: (id: number, product: ProductUpdateModel) => Promise<boolean>;
	productsUpdateState: FetchState;

	productToggleStatus: (id: number) => Promise<boolean>;
	toggleState: FetchState;

	updateProductStockStatus: (id: number, status: number) => Promise<boolean>;
	updateStockState: FetchState;

	product?: ProductModel,
	getProductById: (id: number) => Promise<boolean>
	productState: FetchState;

}

const ProductContext = createContext({} as TContextProps);

export const ProducthBackofficeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const productApi = new ProductApiImpl();

	const [products, setProducts] = useState<ProductModel[]>([]);
	const [productsFetchState, setProductsFetchState] = useState<FetchState>({ loading: false, error: '' });

	const [productsUpdateState, setProductsUpdateState] = useState<FetchState>({ loading: false, error: '' });

	const [toggleState, setToggleState] = useState<FetchState>({ loading: false, error: '' });
	const [updateStockState, setUpdateStockState] = useState<FetchState>({ loading: false, error: '' });

	const [product, setProduct] = useState<ProductModel>();
	const [productState, setProductState] = useState<FetchState>({ loading: false, error: '' });

	const getProductByFilter = async (filter: FilterParams) => {
		setProductsFetchState({ loading: true, error: '' });
		return productApi.getProductsByFilter(filter).then((result) => {
			setProducts(result.data);
			return result.total;
		}).catch((err) => {
			const responseError = err as ApiResponse<void>;
			setProductsFetchState((prev: FetchState) => ({ ...prev, error: responseError.message }));
			return null;
		}).finally(() => {
			setProductsFetchState((prev: FetchState) => ({ ...prev, loading: false }));
		});
	};

	const updateProduct = async (id: number, prod: ProductUpdateModel) => {
		setProductsUpdateState({ loading: true, error: '' });
		return productApi.updateProduct(id, prod).then(() => true).catch((err) => {
			const responseError = err as ApiResponse<void>;
			setProductsUpdateState((prev: FetchState) => ({ ...prev, error: responseError.message }));
			return false;
		}).finally(() => {
			setProductsUpdateState((prev: FetchState) => ({ ...prev, loading: false }));
		});
	};

	const productToggleStatus = async (id: number) => {
		setToggleState({ loading: true, error: '' });
		return productApi.productToggleStatus(id).then(() => true).catch((err) => {
			const responseError = err as ApiResponse<void>;
			setToggleState((prev: FetchState) => ({ ...prev, error: responseError.message }));
			return false;
		}).finally(() => {
			setToggleState((prev: FetchState) => ({ ...prev, loading: false }));
		});
	};

	const updateProductStockStatus = async (id: number, status: number) => {
		setUpdateStockState({ loading: true, error: '' });
		return productApi.updateProductStockStatus(id, status).then(() => true).catch((err) => {
			const responseError = err as ApiResponse<void>;
			setUpdateStockState((prev: FetchState) => ({ ...prev, error: responseError.message }));
			return false;
		}).finally(() => {
			setUpdateStockState((prev: FetchState) => ({ ...prev, loading: false }));
		});
	};

	const getProductById = async (id: number) => {
		setProductState({ loading: true, error: '' });
		return productApi.getProductsById(id)
			.then((result) => {
				setProduct(result.data);
				return true;
			}).catch((err) => {
				const responseError = err as ApiResponse<void>;
				setProductState((prev: FetchState) => ({ ...prev, error: responseError.message }));
				return false;
			}).finally(() => {
				setProductState((prev: FetchState) => ({ ...prev, loading: false }));
			});
	};

	const value = useMemo(() => ({
		products,
		setProducts,
		getProductByFilter,
		productsFetchState,
		updateProduct,
		productsUpdateState,
		productToggleStatus,
		updateProductStockStatus,
		toggleState,
		updateStockState,
		product,
		getProductById,
		productState,
	}), [
		products,
		productsFetchState, productsUpdateState, toggleState, updateStockState, productState, product]);

	return <ProductContext.Provider value={value}>{children}</ProductContext.Provider>;
};

export default function useProductBackoffice() {
	return useContext(ProductContext);
}
