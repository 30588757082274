import {
	Avatar, Stack, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PersonOutline, Search, ShoppingCart } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate, useSearchParams } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import DefaultButton from '../default-button/DefaultButton';
import StyledBadge from '../styled-badge/StyledBadge';
import TextField from '../text-field/TextField';
import useCart from '../../providers/cart.provider';
import useSearch from '../../../../features/products/presentation/provider/search.provider';
import useAuth from 'features/authentication/presentation/provider/auth.provider';

const AppBarSearch = () => {
	const { t } = useTranslation('translations');
	const [searchParams, setSearchParams] = useSearchParams();
	const { search, setSearch } = useSearch();
	const [query, setQuery] = useState<string>('');
	const pageProductmatch = useMatch(AppLinks.PRODUCTS);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { userAuth } = useAuth();

	const theme = useTheme();
	const navigate = useNavigate();
	const isMd = useMediaQuery(theme.breakpoints.up('md'));
	const { cartProducts, getTotalPrice } = useCart();

	useEffect(() => {
		setQuery(searchParams.get('search') || '');
	}, []);

	useEffect(() => {
		if (!search) {
			setQuery('');
		}
	}, [search]);

	const handleCartClick = () => {
		navigate(AppLinks.CART);
	};

	const handleSearch = () => {
		if (pageProductmatch) {
			setSearchParams(`?search=${query}`);
			setSearch(query);
		} else {
			navigate(`${AppLinks.PRODUCTS}?search=${query}`);
		}
	};

	return (
		<Stack direction="row" alignItems="center" gap={{ md: 2 }} justifyContent="space-between">
			<Stack direction="row">
				<TextField
					fullWidth
					placeholder="Que produto procura ?"
					value={query}
					onChange={(event) => setQuery(event.target.value)}
					onKeyUp={(event) => {
						if (event.key === 'Enter') {
							handleSearch();
						}
					}}
				/>
				<DefaultButton onClick={() => handleSearch()}>
					<Search sx={{ fontSize: '17px' }} />
				</DefaultButton>
			</Stack>
			<Stack alignItems="center">
				<DefaultButton sx={{ mt: 1, mb: 0 }} variant="text" size="small" color="inherit" onClick={handleCartClick}>
					<StyledBadge badgeContent={cartProducts.length}>
						<ShoppingCart />
					</StyledBadge>
					{isMd && t('cart')}
				</DefaultButton>
				<Typography fontWeight={600}>
					{getTotalPrice().toFixed(2)}
					{' '}
					€
				</Typography>
			</Stack>

			{isMd && !userAuth && (
				<DefaultButton variant="text" color="inherit" onClick={() => navigate(AppLinks.LOGIN)}>
					<PersonOutline sx={{
						display: {
							md: 'block', xs: 'none', width: 32, height: 32,
						},
					}}
					/>
				</DefaultButton>
			)}

			{isMd && userAuth && (
				<Avatar
					alt={userAuth.fullName}
					src={userAuth.profileImage}
					onClick={() => navigate(AppLinks.LOGIN)}
					sx={{ width: 48, height: 48, cursor: 'pointer' }}
				/>
			)}
		</Stack>
	);
};

export default AppBarSearch;
