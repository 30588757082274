import { Tabs, Tab } from '@mui/material';
import React from 'react';

interface Props {
	value: number
	handleChange: (event: React.SyntheticEvent, newValue: number) => void
}

const TabBar: React.FC<Props> = (props) => {
	const { value, handleChange } = props;
	return (
		<Tabs
			value={value}
        	onChange={handleChange}
        	aria-label="wrapped label tabs example"
			indicatorColor="secondary"
			textColor="inherit"
			sx={{ pl: 2 }}
		>
			<Tab
				value={1}
				label="O meu perfil"
			/>
			<Tab value={2} label="Categorias e subcategorias " />
			<Tab value={3} label="Clientes e categorização" />
			<Tab value={4} label="Definição de stock" />
			<Tab value={5} label="Definição de portes" />
		</Tabs>
	);
};

export default TabBar;
