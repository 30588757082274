import { Box, useTheme } from '@mui/material';
import React from 'react';
import TabBar from './tabbar/TabBar';
import Dimens from 'assets/dimens';
import TabPanel from './tabbar/TabPanel';
import MyProfile from './my-profile/MyProfile';
import Categories from './categories/Categories';
import Clients from './clients/Clients';
import StockDefinition from './stock-definition/StockDefinition';
import PortsDefinition from './ports-definition/PortsDefinition';

export const SettingsPage = () => {
	const theme = useTheme();

	const [value, setValue] = React.useState(1);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return (
		<Box sx={{
			borderRadius: Dimens.CARD_BORDER_RADIUS,
			bgcolor: theme.palette.background.default,
			boxShadow: 'none',
		}}
		>
			<TabBar value={value} handleChange={handleChange} />
			<TabPanel value={value} index={1}><MyProfile /></TabPanel>
			<TabPanel value={value} index={2}><Categories /></TabPanel>
			<TabPanel value={value} index={3}><Clients /></TabPanel>
			<TabPanel value={value} index={4}><StockDefinition /></TabPanel>
			<TabPanel value={value} index={5}><PortsDefinition /></TabPanel>
		</Box>
	);
};
