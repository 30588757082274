import {
	Card, CardContent, FormControl,
	MenuItem, Select, SelectChangeEvent, Stack, Typography, useTheme,
} from '@mui/material';
import Dimens from 'assets/dimens';
import { ReactComponent as SalesIcon } from 'assets/icons/car-sales-values-icon.svg';
import { ReactComponent as BagIcon } from 'assets/icons/card-bag-icon.svg';
import { ReactComponent as NewClientsIcon } from 'assets/icons/card-new-clients-icon.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const CardInfo = () => {
	const [age, setAge] = React.useState('');
	const { t } = useTranslation('translations');
	const theme = useTheme();

	const handleChange = (event: SelectChangeEvent) => {
		setAge(event.target.value);
	};
	return (
		<Stack direction="row" spacing={2} flex={1}>
			{/* Card Sales */}
			<Card sx={{ flex: 0.7, boxShadow: 'none', borderRadius: 3 }}>
				<CardContent>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							marginBottom: '16px',
						}}
					>
						<div>
							<SalesIcon />
						</div>
						<div>
							<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
								<Select
									value={age}
									onChange={handleChange}
									displayEmpty
								>
									<MenuItem value="">
										<em>Este mês</em>
									</MenuItem>
									<MenuItem value={10}>Ten</MenuItem>
									<MenuItem value={20}>Twenty</MenuItem>
									<MenuItem value={30}>Thirty</MenuItem>
								</Select>
							</FormControl>

						</div>
					</div>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<Typography
								sx={{
									fontSize: Dimens.FONT_SIZE_SMALL_TITLE,
									fontWeight: Dimens.FONT_THIN,
									color: '#8B8D97',
								}}
							>
								{t('card_sales_order_title')}

							</Typography>
							<Typography
								sx={{ color: '#45464E', fontSize: 28, fontWeight: Dimens.FONT_BOLD }}
							>
								320,22 €

							</Typography>
						</div>
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<Typography
								sx={{
									fontSize: Dimens.FONT_SIZE_SMALL_TITLE,
									fontWeight: Dimens.FONT_THIN,
									color: '#8B8D97',
								}}
							>
								{t('card_amount_title')}

							</Typography>
							<div style={{ display: 'flex' }}>
								<Typography
									sx={{
										color: '#45464E', fontSize: 28, fontWeight: Dimens.FONT_BOLD, paddingRight: 1,
									}}
								>
									123

								</Typography>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<Typography sx={{ color: theme.palette.secondary.main }}>+20.00%</Typography>
									<Typography
										sx={{
											color: theme.palette.secondary.main,
											fontSize: Dimens.FONT_SIZE_EXTRA_SMALL,
										}}
									>
										(semana anterior)
									</Typography>

								</div>
							</div>
						</div>

					</div>
				</CardContent>
			</Card>
			 {/* Card New Customers */}
			<Card sx={{ flex: 0.7, boxShadow: 'none', borderRadius: 3 }}>
				<CardContent>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							marginBottom: '16px',
						}}
					>
						<div>
							<NewClientsIcon />
						</div>
						<div>
							<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
								<Select
									value={age}
									onChange={handleChange}
									displayEmpty
								>
									<MenuItem value="">
										<em>Esta semana</em>
									</MenuItem>
									<MenuItem value={10}>Ten</MenuItem>
									<MenuItem value={20}>Twenty</MenuItem>
									<MenuItem value={30}>Thirty</MenuItem>
								</Select>
							</FormControl>

						</div>
					</div>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<Typography
								sx={{
									fontSize: Dimens.FONT_SIZE_SMALL_TITLE,
									fontWeight: Dimens.FONT_THIN,
									color: '#8B8D97',
								}}
							>
								{t('card_new_customers_title')}

							</Typography>
							<div style={{ display: 'flex' }}>
								<Typography
									sx={{
										color: '#45464E', fontSize: 28, fontWeight: Dimens.FONT_BOLD, paddingRight: 1,
									}}
								>
									12

								</Typography>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<Typography sx={{ color: theme.palette.secondary.main }}>+20.00%</Typography>
									<Typography
										sx={{
											color: theme.palette.secondary.main,
											fontSize: Dimens.FONT_SIZE_EXTRA_SMALL,
										}}
									>
										(semana anterior)
									</Typography>

								</div>
							</div>
						</div>
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<Typography
								sx={{
									fontSize: Dimens.FONT_SIZE_SMALL_TITLE,
									fontWeight: Dimens.FONT_THIN,
									color: '#8B8D97',
								}}
							>
								{t('registered_customets_title')}

							</Typography>
							<Typography
								sx={{ color: '#45464E', fontSize: 28, fontWeight: Dimens.FONT_BOLD }}
							>
								320

							</Typography>
						</div>
					</div>
				</CardContent>
			</Card>
			{/* Card Black */}
			<Card sx={{
				flex: 1, backgroundColor: '#5C6464', boxShadow: 'none', borderRadius: 3,
			}}
			>
				<CardContent>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							marginBottom: '16px',
						}}
					>
						<div>
							<BagIcon />
						</div>
						<div>
							<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
								<Select
									value={age}
									onChange={handleChange}
									displayEmpty
								>
									<MenuItem value="">
										<em style={{ color: theme.palette.secondary.contrastText }}>Este mês</em>
									</MenuItem>
									<MenuItem value={10}>Ten</MenuItem>
									<MenuItem value={20}>Twenty</MenuItem>
									<MenuItem value={30}>Thirty</MenuItem>
								</Select>
							</FormControl>

						</div>
					</div>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<Stack direction="row" spacing={2}>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<Typography
									sx={{
										fontSize: Dimens.FONT_SIZE_SMALL_TITLE,
										fontWeight: Dimens.FONT_THIN,
										color: theme.palette.secondary.contrastText,
									}}
								>
									{t('card_all_orders')}

								</Typography>
								<div style={{ display: 'flex' }}>
									<Typography
										sx={{
											color: theme.palette.secondary.contrastText,
											 fontSize: 28,
											fontWeight: Dimens.FONT_BOLD,
											paddingRight: 1,
										}}
									>
										123

									</Typography>
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<Typography sx={{ color: theme.palette.secondary.main }}>+20.00%</Typography>
										<Typography
											sx={{
												color: theme.palette.secondary.main,
												fontSize: Dimens.FONT_SIZE_EXTRA_SMALL,
											}}
										>
											(semana anterior)
										</Typography>

									</div>
								</div>
							</div>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<Typography
									sx={{
										fontSize: Dimens.FONT_SIZE_SMALL_TITLE,
										fontWeight: Dimens.FONT_THIN,
										color: theme.palette.secondary.contrastText,
									}}
								>
									{t('card_pendent_orders')}

								</Typography>
								<Typography
									sx={{
										color: theme.palette.secondary.contrastText,
										 fontSize: 28,
										fontWeight: Dimens.FONT_BOLD,
									}}
								>
									320

								</Typography>
							</div>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<Typography
									sx={{
										fontSize: Dimens.FONT_SIZE_SMALL_TITLE,
										fontWeight: Dimens.FONT_THIN,
										color: theme.palette.secondary.contrastText,
									}}
								>
									{t('card_send_orders')}

								</Typography>
								<Typography
									sx={{
										color: theme.palette.secondary.contrastText,
										 fontSize: 28,
										fontWeight: Dimens.FONT_BOLD,
									}}
								>
									22

								</Typography>
							</div>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<Typography
									sx={{
										fontSize: Dimens.FONT_SIZE_SMALL_TITLE,
										fontWeight: Dimens.FONT_THIN,
										color: theme.palette.secondary.contrastText,
									}}
								>
									{t('card_complete_orders')}

								</Typography>
								<Typography
									sx={{
										color: theme.palette.secondary.contrastText,
										 fontSize: 28,
										fontWeight: Dimens.FONT_BOLD,
									}}
								>
									32

								</Typography>
							</div>
						</Stack>
					</div>

				</CardContent>
			</Card>

		</Stack>

	);
};
