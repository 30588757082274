/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
	Card, useTheme, Box, Stack, SvgIcon,
} from '@mui/material';
import { BodyText, DefaultButton } from 'common/presentation/components';
import Dimens from 'assets/dimens';
import { FileEye } from 'assets/icons';
import { zilaSlab } from 'assets/theme/theme';

interface Props {
	title: string;
	image: string;
	fileLink: string;
	onSeeClick: () => void
}

const CatelogItem: React.FC<Props> = (props) => {
	const theme = useTheme();
	const {
		title, image, fileLink, onSeeClick,
	} = props;

	let imageUrl = image;
	if (image) {
		const imageUrlArr = image.split('/');
		imageUrl = `https://dev.host.volt.pt/static-files/uploaded-images/${imageUrlArr[imageUrlArr.length - 1]}`;
	}

	return (
		<Card
			elevation={0}
			sx={{
				width: '230px',
				minWidth: '230px',
				height: '325px',
				boxShadow: ` 0px 0px 1.80666px ${theme.palette.secondary.main}`,
				borderTopLeftRadius: 0,
				borderTopRightRadius: 0,
				justifyItems: 'center',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Box sx={{
				bgcolor: theme.palette.secondary.main,
				pt: Dimens.DEFAULT_PADDING,
				pb: Dimens.DEFAULT_PADDING,
			}}
			>
				<BodyText
					textAlign="center"
					title={title.toUpperCase()}
					sx={{
						fontFamily: zilaSlab,
						fontWeight: Dimens.FONT_NORMAL,
						color: theme.palette.background.paper,
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						WebkitLineClamp: '1',
						whiteSpace: 'pre-wrap',
					}}
				>
					{title.toUpperCase()}
				</BodyText>
			</Box>
			<img src={imageUrl} alt="catelog background" width={240} height={240} style={{ objectFit: 'cover' }} />
			<Stack direction="row" justifyContent="space-around" alignItems="center" style={{ marginTop: 4 }}>
				<a href={fileLink} target="_blank" rel="noreferrer">
					<DefaultButton style={{ flex: 1 }}>Download</DefaultButton>
				</a>
			</Stack>
		</Card>
	);
};

export default CatelogItem;
